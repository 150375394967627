import React from "react";

export interface RequireAuthProps {
    authToken: string;
    children: React.ReactElement,
    requiredPath: string
}

export interface CheckAuth {
    children?: React.ReactElement | null
}

export interface LoginState {
    username: string;
    password: string;
    showErrors: boolean;
    errorMsg: string;
    disableAllBtns: boolean;
}

export class AppList {
    app_group?: number;
    app_icon?: string;
    description?: string;
    forwarding_url?: string;
    id?: number;
    is_active?: boolean;
    is_maintaining?: boolean;
    name?: string;
    short_name?: string;
    is_permitted?: boolean;

    constructor(group?: number, icon?: string, shrt_name?: string, desc?: string, is_maintaining?: boolean,
                url?: string, id?: number, name?: string, is_active?: boolean, is_permitted?: boolean) {
        this.app_group = group;
        this.app_icon = icon;
        this.short_name = shrt_name;
        this.description = desc;
        this.is_maintaining = is_maintaining;
        this.forwarding_url = url;
        this.id = id;
        this.is_active = is_active;
        this.name = name;
        this.is_permitted = is_permitted;
    }
}


export class UserData {
    user: string | null;
    token: string | null;
    msg: string;

    constructor(user: string | null, token: string | null, msg: string) {
        this.user = user;
        this.token = token;
        this.msg = msg;
    }
}


export interface HomePageState {
    appsLoading: boolean;
    apps: Array<AppList>;
}