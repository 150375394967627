import React from "react";

class NoPermission extends React.Component<any, any> {


    render() {
        return (
            <>
                <br />
                <br />
                <center>
                    <div className="w3-display-middle mb-3">
                        <h1 className="w3-jumbo w3-animate-top w3-center"><code>Рухсат берилмаган</code></h1>
                        <hr className="w3-border-white w3-animate-left" style={{margin: "auto", width: "50%"}}/>
                        <h3 className="w3-center w3-animate-right">Мазкур ресурдан фойдаланишда Сизга рухсат берилмаган</h3>
                        <h3 className="w3-center w3-animate-zoom">🚫🚫🚫🚫</h3>
                        <h6 className="w3-center w3-animate-zoom">Хатолик коди: 403</h6>
                    </div>
                </center>
            </>
        )
    }
}

export default NoPermission;