import React from "react";
import {LoginState} from "../auth/interfaces";
// @ts-ignore
import gerb from "../images/gerb.png"
import {Alert, Button, Snackbar, TextField} from "@mui/material";
import '../css/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle'
import Services from "../auth/services";
import {setKnoxTokenAndUsername} from "../constants";


class LoginPage extends React.Component<any, LoginState> {

    constructor(props: any) {
        super(props);
        this.state = {
            disableAllBtns: false,
            password: "",
            showErrors: false,
            errorMsg: "",
            username: ""
        }
    }

    private handleLoginForm = (e: any) => {
        e.preventDefault();
        this.setState({disableAllBtns: true})
        if (this.state.username === '' || this.state.password === '') {
            this.setState({showErrors: true, errorMsg: "Логин ва парол киритилиши зарур", username: "", password: ""})
            e.target.reset();
        } else {
            Services.globalLoginAction(this.state.username, this.state.password).then((user) => {
                if (user.token === null) {
                    this.setState({showErrors: true, errorMsg: user.msg, username: "", password: ""})
                    e.target.reset();
                } else {
                    setKnoxTokenAndUsername(user);
                    window.location.href = "/home";
                }
            })
        }
        this.setState({disableAllBtns: false})
    }

    private handleSnackBarClosed() {
        if (this.state.showErrors) {
            this.setState({showErrors: false, errorMsg: ""})
        }
    }

    render() {
        return (
            <>
                <Snackbar
                    open={this.state.showErrors}
                    onClose={() => this.handleSnackBarClosed()}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    autoHideDuration={6000}
                >
                    <Alert severity="error" onClose={() => this.handleSnackBarClosed()}>
                        {this.state.errorMsg}
                    </Alert>
                </Snackbar>
                <section className="background-radial-gradient overflow-hidden">
                    <div className="container px-1 py-5 px-md-5 text-center text-lg-start my-5">
                        <div className="row gx-lg-12 align-items-center mb-5 full-screen">
                            <div className="col-lg-12 mb-5 mb-lg-0" style={{zIndex: 10}}>
                                <center><img style={{zIndex: 11, opacity: 1}} src={gerb} width={200} height={200}
                                             alt=""/></center>
                                <h1 className="my-3 display-5 fw-bold ls-tight text-dark text-center"
                                    style={{color: "white"}}>
                                    Масофавий назорат маркази <br/>
                                </h1>
                                <p style={{fontSize: "25px"}} className="mb-4 opacity-70 text-dark text-center">
                                    Ўзбекистон Республикаси Ҳисоб палатасининг ягона ахборот тизимлари
                                </p>
                                <div className="row">
                                    <center>
                                        <div className="col-lg-6">
                                            <div className="card bg-glass">
                                                <div className="p-4 text-center text-muted">
                                                    <h4>Ягона авторизация тизими</h4>
                                                </div>
                                                <div className="card-body px-4 px-md-5">
                                                    <form onSubmit={(e) => this.handleLoginForm(e)}>
                                                        <div className="form-outline mb-4">
                                                            <TextField
                                                                value={this.state.username}
                                                                disabled={this.state.disableAllBtns}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        username: e.target.value
                                                                    })
                                                                }} size="small" style={{width: "100%"}}
                                                                label="Логинни киритинг"/>
                                                        </div>
                                                        <div className="form-outline mb-4">
                                                            <TextField
                                                                value={this.state.password}
                                                                disabled={this.state.disableAllBtns}
                                                                onChange={(e) => {
                                                                    this.setState({
                                                                        password: e.target.value
                                                                    })
                                                                }} size="small" type="password"
                                                                style={{width: "100%"}}
                                                                label="Паролни киритинг..."/>
                                                        </div>
                                                        <div className="row">
                                                            <Button disabled={this.state.disableAllBtns}
                                                                    type="submit"> Кириш </Button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default LoginPage;