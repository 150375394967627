import {UserData} from "./auth/interfaces";

// export const AUTH_ENDPOINT: string = "http://192.168.0.152:8001";
export const AUTH_ENDPOINT: string = "https://auth.auditsystem.uz";

export const getKnoxToken = () => {
    return localStorage.getItem("token");
}

export const removeKnoxToken = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
}

export const getUser = () => {
    return localStorage.getItem("user");
}

export const setKnoxTokenAndUsername = (user: UserData) => {
    localStorage.setItem("token", user.token!);
    localStorage.setItem("user", user.user!);
}