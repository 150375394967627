import {AppList, UserData} from "./interfaces";
import axios, {AxiosError} from "axios";
import {AUTH_ENDPOINT, getKnoxToken, removeKnoxToken} from "../constants";

class Services {

    public static checkAuth(knoxToken: string, required_path: string): number {
        try {
            if (knoxToken === null) {
                return 401;
            }
            const xmlRequest = new XMLHttpRequest();
            xmlRequest.open("GET", `${AUTH_ENDPOINT}/middlewares/auth/check-views/`, false);
            xmlRequest.setRequestHeader("Authorization", "Token " + knoxToken);
            xmlRequest.setRequestHeader("x-required", required_path);
            xmlRequest.send()
            return xmlRequest.status;
        } catch (error) {
            return 401;
        }
    }

    public static checkIfUserLoggedInAlready(): number {
        if (localStorage.getItem("token") === null) {
            return 401;
        }
        try {
            const xmlRequest = new XMLHttpRequest();
            xmlRequest.open("GET", `${AUTH_ENDPOINT}/middlewares/auth/checking/`, false);
            xmlRequest.setRequestHeader("Authorization", "Token " + getKnoxToken());
            xmlRequest.send()
            return xmlRequest.status;
        } catch (error) {
            return 401;
        }
    }

    public static logOutAction() {
        if (localStorage.getItem("token") === null) {
            removeKnoxToken();
            return 200;
        }
        try {
            const xmlRequest = new XMLHttpRequest();
            xmlRequest.open("POST", `${AUTH_ENDPOINT}/auth/logout/`, false);
            xmlRequest.setRequestHeader("Authorization", "Token " + getKnoxToken());
            xmlRequest.send()
            removeKnoxToken();
            return xmlRequest.status;
        } catch (error) {
            return 401;
        }
    }


    public static globalLoginAction(username: string, passwd: string): Promise<UserData> {
        return new Promise<UserData>((resolve, reject) => {
            try {
                const credentials = {
                    username: username,
                    password: passwd
                }
                axios.post(`${AUTH_ENDPOINT}/auth/login/`, credentials).then((result) => {
                    if (result.status === 200) {
                        resolve(result?.data as UserData)
                    } else {
                        resolve(new UserData(null, null, result?.data.msg))
                    }
                }).catch((error: any) => {
                    resolve(new UserData(null, null, error.response?.data.non_field_errors[0]))
                })
            } catch (e) {
                resolve(new UserData(null, null, "Авторизация сервери билан уланишда хатолик юз берди"))
            }
        })
    }

    public static getAppsList(): Promise<Array<AppList>> {
        return new Promise<Array<AppList>>((resolve, reject) => {
            const emptyInstance: Array<AppList> = new Array<AppList>;
            try {
                const headers = {
                    'Authorization': 'Token ' + getKnoxToken()
                }
                axios.get(`${AUTH_ENDPOINT}/middlewares/apps-list/`, {headers}).then((apps) => {
                    resolve(apps?.data as Array<AppList>)
                }).catch((error) => {
                    resolve(emptyInstance)
                })
            } catch (e) {
                reject(emptyInstance)
            }
        })
    }
}

export default Services