import React from "react";
import '../css/style.css'
// @ts-ignore
import gerb from '../images/gerb.png'
// @ts-ignore
import gov from '../images/govs.png'
import {Button, Divider, LinearProgress} from "@mui/material";
import {AppList, HomePageState} from "../auth/interfaces";
import Services from "../auth/services";
import {ReactSVG} from "react-svg"
import {Tabs, Tab} from "react-bootstrap";
import {getKnoxToken, getUser} from "../constants";

class HomePage extends React.Component<{}, HomePageState> {

    constructor(props: {}) {
        super(props);
        this.state = {
            apps: [] as Array<AppList>,
            appsLoading: true
        }
    }

    componentDidMount() {
        Services.getAppsList().then((apps) => {
                this.setState({appsLoading: false, apps: apps})
            }
        )
    }

    private handleLogoutAction() {
        try {
            const logOutAuth: number = Services.logOutAction();
            if (logOutAuth === 200) {
                window.location.href = "/login";
            }
        } catch (e) {
            alert("Авторизация Сервери билан уланишда хатолик юз берди.");
        }
    }


    render() {
        return (
            <>
                <header className="sticky-top navigation">
                    <div className="container">
                        <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
                            <img className="img-fluid" height={50} width={50} src={gerb} alt="remote_control"/>
                            <button className="navbar-toggler border-0" type="button" data-toggle="collapse"
                                    data-target="#navigation">
                                <i className="ti-align-right h4 text-dark"></i></button>
                            <div className="collapse navbar-collapse" id="navigation">
                                <ul className="navbar-nav mx-auto">
                                    <li className="nav-item"><Button style={{color: "white"}}>Асосий</Button></li>
                                    <li className="nav-item"><Button style={{color: "white"}}>Маълумотлар</Button></li>
                                    <li className="nav-item"><Button style={{color: "white"}}>Ёрдам</Button>
                                    </li>
                                </ul>
                                <Button onClick={() => this.handleLogoutAction()} color="error">({getUser()})Чиқиш</Button>
                            </div>
                        </nav>
                    </div>
                </header>

                <section className="section">
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-7 text-center text-lg-left">
                                <h1 className="mb-2">Масофавий назорат маркази</h1>
                                <p className="mb-2">Ўзбекистон Республикаси Ҳисоб палатасининг ягона
                                    ахборот тизимлари</p>
                            </div>
                            <div className="col-lg-4 d-lg-block d-none">
                                <img src={gov} width={350} height={350} alt="main_logo" className="img-fluid"/>
                            </div>
                        </div>
                    </div>
                </section>

                <Divider style={{backgroundColor: "#ccc"}} className="mb-3 text-dark"/>

                <div style={{maxWidth: "80%", margin: "auto"}}>
                    <Tabs fill variant="pills">
                        <Tab title="Ички фаолият" eventKey="internal">
                            <section className="section pb-0">
                                <div className="container">
                                    <div className="row">
                                        {this.state.appsLoading ? (
                                            <LinearProgress/>
                                        ) : this.state.apps.length > 0 ? this.state.apps.filter(G => G.app_group === 1).map(App =>
                                            <>
                                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                                    <div className="card match-height">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <ReactSVG className="mb-4 text-primary"
                                                                              strokeWidth={5}
                                                                              src={require(`../svg/${App.app_icon}.svg`)}/>
                                                                </div>
                                                                <div className="col-lg-6 float-end text-end">
                                                                    {!App.is_permitted ? (
                                                                        <>
                                                                        <span
                                                                            className="badge bg-danger">Рухсат берилмаган</span>
                                                                        </>
                                                                    ) : App.is_maintaining ? (
                                                                        <>
                                                                        <span
                                                                            className="badge bg-danger">Профилактика</span>
                                                                        </>
                                                                    ) : (<></>)}
                                                                </div>
                                                            </div>
                                                            <h3 className="card-title h4">{App.short_name}</h3>
                                                            <p className="card-text text-muted">{App.description}</p>
                                                            {App.is_permitted ? App.is_maintaining ? (<></>) : (
                                                                <>
                                                                    <a href={`${App.forwarding_url}/auth/${getKnoxToken()}`} target="_blank"
                                                                       className="stretched-link"></a>
                                                                </>
                                                            ) : (<></>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <span className="text-danger">Маълумот топилмади</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </Tab>
                        <Tab title="Ташқи фаолият" eventKey="outside">
                            <section className="section pb-0">
                                <div className="container">
                                    <div className="row">
                                        {this.state.appsLoading ? (
                                            <>
                                                <LinearProgress/>
                                            </>
                                        ) : this.state.apps.length > 0 ? this.state.apps.filter(G => G.app_group === 2).map(out =>
                                            <>
                                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                                    <div className="card match-height">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <ReactSVG className="mb-4 text-primary"
                                                                              strokeWidth={5}
                                                                              src={require(`../svg/${out.app_icon}.svg`)}/>
                                                                </div>
                                                                <div className="col-lg-6 float-end text-end">
                                                                    {!out.is_permitted ? (
                                                                        <>
                                                                        <span
                                                                            className="badge bg-danger">Рухсат берилмаган</span>
                                                                        </>
                                                                    ) : out.is_maintaining ? (
                                                                        <>
                                                                        <span
                                                                            className="badge bg-danger">Профилактика</span>
                                                                        </>
                                                                    ) : (<></>)}
                                                                </div>
                                                            </div>
                                                            <h3 className="card-title h4">{out.short_name}</h3>
                                                            <p className="card-text text-muted">{out.description}</p>
                                                            {out.is_permitted ? out.is_maintaining ? (<></>) : (
                                                                <>
                                                                    <a href={out.forwarding_url} target="_blank"
                                                                       className="stretched-link"></a>
                                                                </>
                                                            ) : (<></>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <span className="text-danger">Маълумот топилмади</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </Tab>
                        <Tab title="Масофавий аудит ААТ" eventKey="remote_control">
                            <section className="section pb-0">
                                <div className="container">
                                    <div className="row">
                                        {this.state.appsLoading ? (
                                            <>
                                                <LinearProgress/>
                                            </>
                                        ) : this.state.apps.length > 0 ? this.state.apps.filter(G => G.app_group === 3).map(out =>
                                            <>
                                                <div className="col-lg-3 col-md-4 col-sm-6 mb-4">
                                                    <div className="card match-height">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-6">
                                                                    <ReactSVG className="mb-4 text-primary"
                                                                              strokeWidth={5}
                                                                              src={require(`../svg/${out.app_icon}.svg`)}/>
                                                                </div>
                                                                <div className="col-lg-6 float-end text-end">
                                                                    {!out.is_permitted ? (
                                                                        <>
                                                                        <span
                                                                            className="badge bg-danger">Рухсат берилмаган</span>
                                                                        </>
                                                                    ) : out.is_maintaining ? (
                                                                        <>
                                                                        <span
                                                                            className="badge bg-danger">Профилактика</span>
                                                                        </>
                                                                    ) : (<></>)}
                                                                </div>
                                                            </div>
                                                            <h3 className="card-title h4">{out.short_name}</h3>
                                                            <p className="card-text text-muted">{out.description}</p>
                                                            {out.is_permitted ? out.is_maintaining ? (<></>) : (
                                                                <>
                                                                    <a href={`${out.forwarding_url}/auth/${getKnoxToken()}`} target="_blank"
                                                                       className="stretched-link"></a>
                                                                </>
                                                            ) : (<></>)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <span className="text-danger">Маълумот топилмади</span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </Tab>
                    </Tabs>
                </div>
            </>
        );
    }
}

export default HomePage;