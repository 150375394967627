import React from "react";
import {Navigate} from "react-router-dom";
import {CheckAuth} from "./interfaces";
import Services from "./services";


const CheckAuths: React.FC<CheckAuth> = ({children}): any => {
    const authResponse = Services.checkIfUserLoggedInAlready()
    switch (authResponse) {
        case 200: {
            return <Navigate to="/home"/>
        }
        case 401: {
            return <>{children}</>
        }
        case 403: {
            return (
                <>
                    {children}
                </>
            )
        }
    }
}

export default CheckAuths;