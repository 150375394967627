import React from "react";
import {Navigate} from "react-router-dom";
import {RequireAuthProps} from "./interfaces";
import Services from "./services";
import NoPermission from "../containers/no-permission";


const RequireAuth: React.FC<RequireAuthProps> = ({authToken, children, requiredPath}): any => {
    const authResponse = Services.checkAuth(authToken, requiredPath);
    switch (authResponse) {
        case 200: {
            return <>{children}</>
        }
        case 401: {
            return <Navigate to="/login"/>
        }
        case 403: {
            return (
                <>
                    <NoPermission/>
                </>
            )
        }
    }
}

export default RequireAuth;