import React from 'react';
import {BrowserRouter, Route} from "react-router-dom";
import RequireAuth from "./auth/require-auth";
import HomePage from "./containers/home-page";
import LoginPage from "./containers/login-page";
import {Routes} from "react-router-dom"
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import CheckAuth from "./auth/check-auth";
import NotFoundPage from "./containers/not-found-page";


interface AuthState {
    authToken: string;
}

class App extends React.Component<{}, AuthState> {

    constructor(props: {}) {
        super(props);
        this.state = {
            authToken: this.handleToken(),
        }
    }

    private handleToken = () => {
        return localStorage.getItem("token")!;
    }

    render() {
        const {authToken} = this.state;
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<CheckAuth><LoginPage/></CheckAuth>}/>
                    <Route path="/" element={<CheckAuth><LoginPage/></CheckAuth>}/>
                    <Route path="/home"
                           element={<RequireAuth authToken={authToken}
                                                 requiredPath="view/v14/home"><HomePage/></RequireAuth>}/>
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </BrowserRouter>
        )
    }
}

export default App;
