import React from "react";

class NotFoundPage extends React.Component<any, any> {

    render() {
        return (
            <>
                <center>
                    <div style={{margin: "auto", marginTop: "20%"}}>
                        <h2>Ресурс топилмади</h2>
                    </div>
                </center>
            </>
        );
    }
}

export default NotFoundPage;